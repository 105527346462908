import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby'

import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton";
import LineArrowRight from "../../components/Icons/LineArrowRight"

const HeroCover = styled.div`
	position: relative;
	z-index: 0;
	text-align: center;
`

const HeroCaption = styled.div`
	margin: 0 auto;
	background: linear-gradient(to bottom, #5FC6FF, #4FC4FF 94%, rgba(79, 196, 255, 0.1));
	padding:20px 0;
	position: relative;	
	z-index: 1;
	.container {
		width:100%;
	}
	@media(min-width:768px){
		position: absolute;
		background:none;
		padding:0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width:100%;
		max-width: 540px;
	}
	@media(min-width:992px){
		max-width: 850px;
	}
	@media(min-width:1200px){
		max-width: 992px;
	}
	@media(min-width:1600px){
		max-width: 1024px;
	}
`
const HeroTitle = styled.h1`
	color: #fff;
	font-weight: bold;
	text-shadow:0 1px 4px rgba(0, 0, 0,0.9);	
	font-size: 32px;
	line-height: 50px;
	margin-bottom: 10px;
	@media(min-width:768px){
		margin-bottom: 20px;
		text-shadow:0 1px 8px rgba(0, 0, 0,0.9);	
		font-size: 42px;
		line-height: 60px;
	}
	@media(min-width:992px){
		font-size: 52px;
		line-height: 70px;
	}
	@media(min-width:1200px){
		font-size: 62px;
		line-height: 80px;
	}
	@media(min-width:1600px){
		font-size: 72px;
		line-height: 90px;
	}
`

const HeroDescription = styled.div`
	p{
		color: #fff;
		font-size: 16px;
		line-height: 28px;
		text-shadow: 0 1px 1px rgba(0, 0, 0,0.95);
		margin-bottom: 20px;
		@media(min-width:768px){
			margin-bottom: 30px;
			text-shadow: 0 1px 2px rgba(0, 0, 0,0.95);
			font-size: 18px;
			line-height: 28px;
		}
		@media(min-width:992px){
			font-size: 20px;
			line-height: 30px;
		}		
		@media(min-width:1200px){
			font-size: 22px;
			line-height: 32px;
		}
		@media(min-width:1600px){
			font-size: 24px;
			line-height: 34px;
		}
	}
`

const HeroImage = styled.div`
	position: relative;
	margin-top:-20px;
	@media(min-width:768px){
		margin-top:0;
	}

`


const Bureau = styled.div`
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  left:0;
  z-index: 1;
  display:flex;  
  &:hover{
    .bcontent{
      width:100%;
    }
  }
`
const Blogo = styled.div`
  border-radius: 5px;
  width:44px;
  min-width:44px;
  display:flex;
  align-items:center;
  justify-content:center;
  background: #035a78;
  @media (min-width: 992px) {
    background: #fff;
  }
  
`
const BContent = styled.div`
  display:flex;
  width: 0;
  overflow: hidden;
  transition: width 0.5s ease;
`
const BText = styled.div`
  border-radius: 0px 5px 5px 0px;
  text-transform: uppercase;
  background: #035a78;
  font-size: 14px;
  font-weight: bold;
  color: white;
  line-height: 20px;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 120px;
  width: 100%;
  
`
const BRate = styled.div`
  margin-left: 4px;
  display: inline-block;
  vertical-align: bottom;
  width: 52px;
  border-radius: 5px;
  background: #035a78;
  color: white;
  text-align: center;
  padding: 7px 5px;
  div {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  p {
    font-size: 10px;
    line-height: 1.2;
    color: white;
    margin: 0;
  }
`


const Hero = ({ data }) => {
	return(
		<HeroCover>
			<HeroCaption>
				<div className="container">
					<HeroTitle>{data.title}</HeroTitle>
					<HeroDescription>
						<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
						<Link to="/gallery"><PrimaryLinkButton text="Explore our buildings" icon={<LineArrowRight />} /></Link>
					</HeroDescription>
				</div>
			</HeroCaption>
			<HeroImage>
				<GatsbyImage image={getImage(data.image.gatsbyImageData)} alt="Your Best Local Online Dealer for Outdoor Buildings" />
				<Bureau>
					<Blogo><StaticImage src="../../images/bbb.png" alt="bbbussines" width="32" height="44" /></Blogo>
					<BContent className="bcontent">
						<BText>accredited business</BText>
						<BRate><div>A+</div><p>RATING</p></BRate>
					</BContent>
				</Bureau>
			</HeroImage>
		</HeroCover>
	);
}

export default Hero;